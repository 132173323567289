var $header = $('#header');
var headerHeight = $header.outerHeight();

// import components
// import './components/ie-edge-detection.js';
import './components/menu.js';
import './components/accordions.js';
// import './components/animations.js';

// https://ehsangazar.com/optimizing-javascript-event-listeners-for-performance-e28406ad406c
// function throttle (callback, limit) {
//   var wait = false;
//   return function () {
//     if (!wait) {
//       callback.apply(null, arguments);
//       wait = true;
//       setTimeout(function () {
//         wait = false;
//       }, limit);
//     }
//   }
// }

// https://ehsangazar.com/optimizing-javascript-event-listeners-for-performance-e28406ad406c
// function debounce(func, wait, immediate) {
// 	var timeout;
// 	return function() {
// 		var context = this, args = arguments;
// 		var later = function() {
// 			timeout = null;
// 			if (!immediate) func.apply(context, args);
// 		};
// 		var callNow = immediate && !timeout;
// 		clearTimeout(timeout);
// 		timeout = setTimeout(later, wait);
// 		if (callNow) func.apply(context, args);
// 	};
// };

// init

// AOS animations
AOS.init({
  // once: true,
  // disable: 'mobile'
});  

// Parallax
// $(window).ready(function() {
//   $('.parallax').paroller();
//   // console.log($('.parallax'));
//   // $(".paroller").paroller({ factor: '-1', type: 'foreground', direction: 'horizontal' });
// });

// ----------------------------------------
//  scroll to
function scrollTo(e) {

  var href = $.attr(this, 'href');
  if (typeof href === 'undefined') {
      href = $.attr(this, 'data-href');
  }
  href = href.replace('/', '');
  var offset = $.attr(this, 'data-offset');

  var $target = $(href);
  headerHeight = 0;
  if ($target.length) {
      e.preventDefault();
      
      // zavri menu
      $('.nav__toggle').removeClass('is-active');
      $('body').removeClass('menu-active');

      $('html,body').animate({
        scrollTop: $target.offset().top - headerHeight + ((typeof offset !== 'undefined') ? parseInt(offset) : 10)
      }, {
        duration: 500,
        easing: 'swing'
      });
  }
}
$('.scrollTo, .menu-item-type-custom a').on('click', scrollTo);

// ----------------------------------------

// scripts here

// bounce rate event
// var stayOnPage = 10000;
// setTimeout(function(e) {
//   var label = 'na webu alespoň ' + (stayOnPage / 1000) + 's';
//   gtag('event', 'bounce rate fix', {'event_category': 'navstevnik','event_label': label});
// }, stayOnPage);



// ScrollTop
$('#scrollTop').on('click', function(e) {

  $('html,body').animate({
      scrollTop: 0
  }, {
      duration: 300,
      easing: 'swing'
  });
});


// strip on top
$('.strip').on('click', '.strip__close', function(e) {
  e.preventDefault();
  $(this).closest('.strip').slideUp();
  $.get($(this).attr('href'));
});

// search
$('#search .search__button').on('click', function(e) {
  e.preventDefault();
  $(this).closest('.search').addClass('is-active');
});

$('#search .close').on('click', function(e) {
  e.preventDefault();
  $(this).closest('.search').removeClass('is-active');
});

function initLightbox() {
  $('.gallery').each(function() { // the containers for all your galleries
      $(this).magnificPopup({
          delegate: 'a',
          gallery: {
              enabled: true
          },
          type: 'image',
          removalDelay: 500, //delay removal by X to allow out-animation
          callbacks: {
          beforeOpen: function() {
              // just a hack that adds mfp-anim class to markup
              this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
              this.st.mainClass = this.st.el.attr('data-effect');
          }
          },
          image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
          },
          // iframe: {
          //   markup: '<div class="mfp-iframe-scaler">'+
          //             '<div class="mfp-close"></div>'+
          //             '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
          //           '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
          
          //   patterns: {
          //     youtube: {
          //       index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).
          
          //       id: 'v=', // String that splits URL in a two parts, second part should be %id%
          //       // Or null - full URL will be returned
          //       // Or a function that should return %id%, for example:
          //       // id: function(url) { return 'parsed id'; }
          
          //       src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
          //     },                      
          //     // you may add here more sources
          
          //   },
          
          //   srcAction: 'iframe_src', // Templating object key. First part defines CSS selector, second attribute. "iframe_src" means: find "iframe" and set attribute "src".
          // },
          closeOnContentClick: true,
          midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
      });
  });
}
initLightbox();

// $('.timeline__months').slick({
//   infinite: true,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   dots: false,
//   arrows: true,
// });


$('.timeline__days').slick({
  infinite: false,
  slidesToShow: 10,
  slidesToScroll: 5,
  dots: false,
  arrows: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 8, 
        slidesToScroll: 5,             
      }
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 5, 
        slidesToScroll: 2,             
      }
    },
  ],
});

// timeline
$('#timelineDays').on('click', 'a', function(e) {
  e.preventDefault();
  var day = $(this).data('day');
  console.log(day);

  $('#timelineDays a').removeClass('on');
  $(this).addClass('on');

  var $card = $('.card[data-day="' + day + '"]')[0];
  console.log($card);

  if ($card) {
    $('html,body').animate({
      scrollTop: $($card).offset().top
    }, {
      duration: 500,
      easing: 'swing'
    });
  }

});

// Alerts
const alertList = document.querySelectorAll('.alert');
const alerts = [...alertList].map(element => new bootstrap.Alert(element));

// floating Tickets and detial button toggle
$('#floatingBtnTickets').on('click', function(e) {
  e.preventDefault();
  $(this).toggleClass('active');
  $('body').toggleClass('popup-active');
  $('#detailPopup').toggleClass('active');
});


// #scrollToTop scroll to top on click
$('#scrollToTop').on('click', function(e) {
  e.preventDefault();
  $('html,body').animate({
    scrollTop: 0
  }, {
    duration: 500,
    easing: 'swing'
  });
});