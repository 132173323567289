
// accordion scripts
;(function ($) {

  "use strict";


  var lastActiveAccordeonItemOffset = $('.accordion-header a.current').last().offset();

  if(lastActiveAccordeonItemOffset && lastActiveAccordeonItemOffset.top) {

    if(window.navigation.canGoForward) {
        // do not scroll to last active accordion item if user is returning from a detail page
        return;
    }

    $('html,body').animate({
        scrollTop: lastActiveAccordeonItemOffset.top - 100
        }, 500, 'swing'
    );
  }

})(jQuery);
